import React from 'react';
import './privacy.css';

const Privacy = () => {
	return (
		<div className="section detail_list_section">
			<div className="section_info">
				<h2 className="section_title">개인정보 처리 방침</h2>
			</div>
			<div className="content_area">
				<ul className="privacy_top_content" style={{ paddingLeft:'10px' }}>
					<li className="top_content_li">
						(주)펫닥과 V+care, Four Paws 등의 패밀리 사이트(이하 "펫닥" 또는 "회사"라고 한다)은 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 최선을 다하고 있습니다.
						펫닥은 통신비밀보호법, 전기통신사업법, 정보통신망 이용 촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보 보호 규정 및 정보통신부가 제정한 개인정보보호지침을 준수하고 있습니다.
						펫닥은 개인정보 처리방침을 통하여 이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다.
					</li>
					<li className="top_content_li">
						펫닥은 관계법령, 정부의 지침 및 펫닥의 정책 변경 등에 따라 개인정보취급방침을 개정할 수 있고,
						개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지하도록 하겠습니다.
					</li>
					<li className="top_content_li">
						펫닥의 개인정보 처리방침은 정부의 법률 및 지침 변경이나 회사의 내부 방침 변경 등으로 인하여 수시로 변경될 수 있고,
						이에 따른 개인정보 처리방침의 지속적인 개선을 위하여 필요한 절차를 정하고 있습니다.
						그리고 개인정보 처리방침을 개정하는 경우나 개인정보 처리방침 변경될 경우 서비스의 첫 페이지의 개인정보처리방침을 통해 고지하고 있습니다.
						이용자들께서는 사이트 방문 시 수시로 확인하시기 바랍니다.
					</li>
				</ul>
				<dl className="policy_content">
					<dt>제 1조 (개인정보 수집 동의)</dt>
					<ul>
						<li>
							펫닥은 이용자들이 회사의 개인정보 처리방침 또는 이용약관의 내용에 대하여 「동의」버튼 또는 「취소」버튼을 클릭할 수 있는 절차를 마련하여,
							「동의」버튼을 클릭하면 펫닥 및 통합 사이트의 개인 정보 수집에 대해 동의한 것으로 봅니다.
						</li>
					</ul>
					<dt>제 2조 (개인정보의 수집·이용 목적)</dt>

					<ul>
						<li>
							다음의 목적을 위하여 개인정보를 수집 및 이용합니다.
							수집 · 이용하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 별도 안내 후 동의를 받는 등 필요한 조치를 이행할 예정입니다.
						</li>
					</ul>
					<ol className="step1">
						<li>
							웹/앱 회원가입 및 관리
							<ol className="step2">
								<li>1) 회원 가입의사 확인</li>
								<li>2) 회원제 서비스 제공에 따른 본인 식별·인증</li>
								<li>3) 회원자격 유지·관리</li>
								<li>4) 제한적 본인확인제 시행에 따른 본인확인</li>
								<li>5) 서비스 부정이용 방지 등</li>
							</ol>
						</li>
						<li>
							재화 또는 서비스 제공
							<ol className="step2">
								<li>1) 상담 서비스 제공</li>
								<li>2) 콘텐츠 제공</li>
								<li>3) 용역 서비스 제공</li>
								<li>4) 맞춤 서비스 제공</li>
								<li>5) 본인인증 등 목적</li>
							</ol>
						</li>
						<li>
							마케팅 및 서비스 품질 개선 등
							<ol className="step2">
								<li>1) 회사의 마케팅, 서비스 품질에 대한 후기(Review)게시, 서비스 개선 및 처리결과 통보 등의 목적</li>
								<li>2) 사용자 식별, 사용자 문의 대응, 제안·불만·AS처리 등의 민원처리</li>
								<li>3) 접속빈도 파악 및 서비스 이용 통계 수집 등 사용자 서비스 이용 분석을 통한 안정적 서비스 운영 및 품질 향상</li>
							</ol>
						</li>
						<li>
							이벤트
							<ol className="step2">
								<li>1) 이벤트 기간 내 변동사항 고지 또는 기간 임박에 대한 안내</li>
								<li>2) 기타 위 수집된 정보를 통해 서비스 제공에 관한 계약 이행 및 요금 정산, 회원관리 등에 활용</li>
							</ol>
						</li>
					</ol>

					<dt>제 3조 (개인정보 항목)</dt>

					<ol className="step1">
						<li>
							펫닥은 개인정보 보호법 제32조에 따라 등록.공개하는 개인정보파일의 처리목적은 다음과 같으며,
							유비플러스 호스팅을 사용하여 고객 관련 데이터(회원 정보, 서비스 구매 내역)를 관리하고 있습니다.
							<ol className="step2">
								<li>
									1) 회원 가입 시
									<ol className="step3">
										<li>- 필수항목: 이름, 생년월일, 비밀번호, 성별, 휴대폰번호</li>
									</ol>
								</li>
								<li>
									2) 재화 또는 서비스 이용 시
									<ol className="step3">
										<li>
											- 필수항목: 반려동물 이름, 품종, 나이, 성별, 중성화 여부, 접종여부, 대금결제를 위해 결제수단에
											따라 지불 결제 사업자에서 제공되는 입력 창을 통해 카드사명, 카드번호, 유효기간, 비밀번호, 개인 또는 법인 여부, 생년월일, 결제통보 이메일 같은 정보 입력이 요청될 수 있습니다.
										</li>
									</ol>
								</li>
							</ol>
						</li>
						<li>회사는 정보통신망법 등에서 규정하고 있는 민감 정보를 수집하지 않습니다.</li>
					</ol>

					<dt>제 4조 (개인정보의 처리 및 보유 기간)</dt>

					<ol className="step1">
						<li>
							회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유, 이용기간 내에서 개인정보를 처리, 보유합니다.
						</li>
						<li>
							회원 가입 시 수집된 개인정보는 수집/이용에 관한 동의일로부터 &lt;서비스 이용종료 시 90일 내&gt; 혹은 &lt;회원 탈퇴 시 파기&gt;까지 위 이용목적을 위하여 보유. 이용됩니다.
							<ol className="step2">
								<li>1) 개인정보 항목: 이메일, 휴대전화번호, 주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 반려동물 정보</li>
								<li>2) 수집방법: 회원가입 단계 통한 수집</li>
								<li>3) 보유근거: 정보주체의 동의</li>
								<li>4) 보유기간: 서비스 이용 종료 시 90일 내 / 탈퇴시 90일 내 파기</li>
							</ol>
						</li>
					</ol>

					<dt>제 5조(개인정보의 제3자 제공 및 목적 외 이용의 제한)</dt>

					<ol className="step1">
						<li>
							펫닥의 수의사 상담 서비스 이용 시 수의사에게 회원의 이름, 닉네임, 반려동물 나이, 반려동물 성별, 반려동물 종류, 반려동물 품종, 접종 여부, 중성화 여부, 지난 상담내역, 위치정보가 제공됩니다.
						</li>
						<li>펫닥의 장례 서비스 이용 시 장례서비스 제공 업체에게 회원님의 이름, 연락처, 위치정보가 제공됩니다.</li>
						<li>
							펫닥은 이용자의 개인정보를 제3자(개인, 법인, 국가, 지방자치단체,기관 등)에 제공하거나 또는 제1조의 개인정보 처리목적을 초과하여 이용하지 않습니다.
							다만 회사는 서비스 품질 개선 및 향상 등의 목적을 위해 이용자의 개인정보를 제3자와 제공(공유를 포함)할 필요가 있는 경우에는 사전에 이용자에게 개인정보를 제공받는 자,
							개인정보를 제공받는 자의 개인정보 이용 목적. 제공하는 개인정보의 항목. 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간, 동의를 거부할 권리가 있다는 사실 및 동의 거부에 따른 불이익이 있는 경우에는
							그 불이익 내용을 서면(전자문서 또는 전자우편을 포함)에 의하여 알리고 동의를 얻을 것이며,
							이용자가 제3자에 대한 개인정보 제공에 동의하지 않을 경우에는 개인정보를 제 3자에게 제공하지 않습니다.
						</li>
						<li>
							제 3항에도 불구하고 다음의 경우에는 이용자 또는 제3자의 이익을 부당하게 침해할 우려가 있을 때를 제외하고는 이용자의 개인정보를 이용자의 동의 없이 제3자에게 제공하거나 목적 외의 용도로 이용할 수 있습니다.

							{/*1) 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우*/}
							{/*2) 공공기관이 법령 등에서 정하는 소관 업무의 수행을 위하여 불가피한 경우*/}
							{/*3) 이용자 또는 그 법정 대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 업는 경우로서 명백히 이용자 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우*/}
							{/*4) 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우*/}
							{/*5) 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관업무를 수행할 수 없는 경우로서 보호위원회의 심의/의결을 거친 경우*/}
							{/*6) 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한 경우*/}
							{/*7) 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우*/}
							{/*8) 법원의 재판업무 수행을 위하여 필요한 경우*/}
							{/*9) 형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우*/}


							<ol className="step2">
								<li>1) 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</li>
								<li>2) 공공기관이 법령 등에서 정하는 소관 업무의 수행을 위하여 불가피한 경우</li>
								<li>
									3) 이용자 또는 그 법정 대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 이용자 또는
									제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
								</li>
								<li>4) 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우</li>
								<li>
									5) 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관업무를 수행할 수 없는 경우로서 보호위원회의 심의/의결을 거친 경우
								</li>
								<li>6) 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한 경우</li>
								<li>7) 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우</li>
								<li>8) 법원의 재판업무 수행을 위하여 필요한 경우</li>
								<li>9) 형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우</li>
							</ol>
						</li>
						<li>
							펫닥은 이용자의 별도 동의를 얻어 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
							<ol className="step2">
								<li>
									1) 수의사 상담 서비스 이용 시
									<ol className="step3">
										<li>- 개인정보 제공받는 자: 수의사 상담서비스 제공 수의사</li>
										<li>
											- 제공받는 자의 개인정보 이용 목적: 수의사 상담 서비스 이행 (상담 서비스 제공, 이용자 확인, 반려동물 정보 확인, 상담 내용 확인)
										</li>
										<li>
											- 제공하는 개인정보 항목: 상담 신청한 이용자의 정보 (이름, 닉네임, 반려동물 나이, 반려동물 성별, 반려동물 종류, 반려동물 품종, 접종 여부, 중성화 여부, 지난 상담내역, 위치정보)
										</li>
										<li>- 제공받는 자의 개인정보 보유 이용기간: 탈퇴 시 까지</li>
									</ol>
								</li>
								<li>
									2) 장례서비스 이용 시
									<ol className="step3">
										<li>- 개인정보 제공받는 자: 장례 서비스 제공 장례업체 (더고마워 등 총 25개)</li>
										<li>- 제공받는 자의 개인정보 이용 목적: 장례 서비스 이행 (장례 서비스 관련 상담 서비스 제공, 이용자 확인, 상담 내용 확인)</li>
										<li>- 제공하는 개인정보 항목: 상담 신청한 이용자의 정보 (이름, 위치정보)</li>
										<li>- 제공받는 자의 개인정보 보유 이용기간: 재화 또는 서비스 제공 목적이 달성된 3년이내 파기</li>
									</ol>
								</li>
							</ol>
						</li>
					</ol>

					<dt>제 6조 (이용자의 권리, 의무 및 행사 방법)</dt>

					<ol className="step1">
						<li>
							이용자는 회사에 대하여 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
						</li>
						<li>
							개인정보의 처리에 관한 정보를 제공받을 권리
							<ol className="step2">
								<li>1) 개인정보의 처리에 관한 동의 여부, 동의 범위 등을 선택하고 결정할 권리</li>
								<li>2) 개인정보의 처리 여부를 확인하고 개인정보에 대한 열람(사본 발급을 포함)을 요구할 권리</li>
								<li>3) 개인정보의 처리 정지, 정정·삭제 및 파기를 요구할 권리</li>
								<li>4) 개인정보의 처리로 인하여 발생한 피해를 신속하고 공정한 절차에 따라 구제받을 권리</li>
							</ol>
						</li>

						<li>
							제 1항에 따른 권리 행사는 회사에 대해 서면(전자문서 또는 전자우편을 포함),
							모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다. 다만, 관련 법령에서 개인정보가
							수집 대상으로 명시되어 있는 경우 회사는 이용자에게 삭제할 수 없음을 알려 드립니다.
						</li>
						<li>
							이용자가 개인정보의 오류 등에 대한 정정을 요청한 경우에 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
						</li>
						<li>
							이용자는 제1항에 따른 권리 행사를 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있으며, 이 경우 이용자는 회사에 대하여 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
						</li>
						<li>
							이용자는 고유식별정보 등 개인정보의 관리 책임을 부담하며, 타인의 개인정보를 도용하거나 사용권한을 넘어 사용하여서는 아니되며, 이로 인하여 발생되는 일체의 법적 책임은 이용자가 부담합니다.
						</li>
						<li>
							이용자는 서비스의 이용과 관련하여 타인의 개인정보를 훼손하여서는 아니됩니다. 만일 이용자가 회사의 서비스와 직·간접적인 영역(온라인 또는 오프라인)에서 타인의 개인정보를 훼손할 경우
							그 훼손으로 인하여 회사 및 이용자에게 발생하는 일체의 유·무형적 손해에 대하여 법적 책임을 부담합니다.
						</li>
					</ol>

					<dt>제 7조(개인정보의 파기)</dt>

					<ol className="step1">
						<li>
							회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
						</li>
						<li>
							이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우,
							회사는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
						</li>
						<li>
							이용자가 회원탈퇴를 요청할 경우, 제공한 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우,
							또는 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 이용자의 개인정보는 제 4조에 따라 파기됩니다.
						</li>
						<li>
							개인정보 파기의 절차 및 방법은 다음과 같습니다.
							<ol className="step2">
								<li>1) 파기절차: 회사는 파기 사유가 발생한 개인정보를 선정하고, 개인정보 보호책임자의 승인 후 개인정보를 파기합니다.</li>
								<li>
									2) 파기방법: 회사는 전자적 파일 형태로 처리된 정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 의하여 처리된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
								</li>
							</ol>
						</li>
						<li>개인정보 보호 책임자는 개인정보 파기 시행 후 파기 결과를 확인합니다.</li>
					</ol>

					<dt>제 8조 (쿠키(Cookie)의 운용 및 거부)</dt>

					<ol className="step1">
						<li>
							쿠키의 사용 목적
							<ol className="step2">
								<li>
									1) 회사는 개인 맞춤 서비스를 제공하기 위해서 이용자에 대한 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
									쿠키는 웹사이트 서버가 이용자의 브라우저에게 전송하는 소량의 정보로서 이용자 컴퓨터의 하드디스크에 저장됩니다.
									<ol className="step3">
										<li>- 회사는 쿠키의 사용을 통해서만 가능한 특정된 맞춤형 서비스를 제공할 수 있습니다.</li>
										<li>- 회사는 회원을 식별하고 회원의 로그인 상태를 유지하기 위해 쿠키를 사용할 수 있습니다.</li>
									</ol>
								</li>
							</ol>
						</li>
						<li>
							쿠키의 설치/운용 및 거부
							<ol className="step2">
								<li>
									1) 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다.
									따라서 이용자는 웹브라우저에서 옵션을 조정함으로써 모든 쿠키를 허용/거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 할 수 있습니다.
									<ol className="step3">
										<li>
											- 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.
											<ol className="step4">
												<li style={{ listStyle:'none' }}>- [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.</li>
												<li style={{ listStyle:'none' }}>- [개인정보 탭]을 클릭합니다.</li>
												<li style={{ listStyle:'none' }}>- [개인정보처리 수준]을 설정하시면 됩니다.</li>
											</ol>
										</li>
										<li>
											- 쿠키의 저장을 거부할 경우에는 개인 맞춤서비스 등 회사가 제공하는 일부 서비스는 이용이 어려울 수 있습니다.
										</li>
									</ol>
								</li>
							</ol>
						</li>
					</ol>

					<dt>제 9조 (비회원 고객의 개인정보 관리)</dt>

					<ol className="step1">
						<li>
							펫닥은 비회원 고객 또한 물품 및 서비스 상품의 구매를 하실 수 있습니다. 당사는 비회원 주문의 경우 배송 및 대금 결제, 상품 배송에 반드시 필요한 개인정보만을 고객님께 요청하고 있습니다.
						</li>
						<li>
							펫닥은 비회원으로 구입을 하신 경우 비회원 고객께서 입력하신 지불인 정보 및 수령인 정보는 대금 결제 및 상품 배송에 관련한 용도 외에는 다른 어떠한 용도로도 사용되지 않습니다.
						</li>
					</ol>

					<dt>제 10조 (개인정보 보호책임자)</dt>
					<ul className="privacy_member">
						<li className="privacy_member_header">
							펫닥은 고객의 개인정보보호를 매우 소중하게 생각하며, 고객의 개인정보가 훼손, 침해, 누설되지 않도록 최선을 다하고 있습니다.
							개인정보보호 담당자와 연락하기를 원하시면 아래의 연락처나 이메일로 문의해 주시기 바랍니다.
							개인정보 관련 문의사항에 대해 신속하고 성실하게 답변해 드릴 수 있도록 하겠습니다.
						</li>
						<li>1) 성명 : 오태환</li>
						<li>2) 소속 : 펫닥</li>
						<li>3) 전자우편 : cert@petdoc.co.kr</li>
						<li>4) 전화번호 : 02-6949-3016</li>
					</ul>

					<dt>제 11조 (만 12세 미만 이용자와 법정대리인)</dt>

					<ol className="step1">
						<li>이용자가 만 14세 미만인 경우 이 개인정보처리방침 상의 이용자 동의는 법정대리인의 동의로 갈음합니다.</li>
						<li>
							만 14세 미만 이용자의 법정대리인은 이용자를 위하여 개인정보처리방침 상의 권리 일체를 행사할 수 있으며,
							법정대리인이 위 권리를 행사할 경우 만 14세 미만 이용자의 법정대리인임을 증명할 수 있는 서류(가족관계증명서, 주민등록등본 등)를 제출하여야 합니다.
						</li>
					</ol>

					<dt>제 12조 (개인정보 처리방침의 공개 및 개정)</dt>

					<ol className="step1">
						<li>
							회사는 이용자가 쉽게 확인할 수 있도록 이 개인정보처리방침을 회사의 홈페이지 또는 어플리케이션에 게재하는 방식으로 공개합니다.
						</li>
						<li>
							개인정보처리방침은 법령, 행정규칙, 행정지침, 회사 서비스 정책 등의 변동에 의하여 개정될 수 있으며,
							이 경우 개정 최소 7일 전(단, 중요한 내용 변경의 경우 30일 전)부터 회사의 인터넷 홈페이지(서비스 초기화면)을 통하여 고지합니다.
						</li>
					</ol>

					<dt id="indexbylaws">부칙</dt>
					이 약관은 2023년 11월 17일부터 적용됩니다.
				</dl>
			</div>
		</div>
	);
};

export default Privacy;
